body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-ponto-embarque {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px rgba(145,145,145,0.40);
}

.bg-success-passageiros {
  background-color: #8cdca1 !important;
}

.bg-info-passageiros {
  background-color: #90b4ff !important;
}

.bg-danger-passageiros {
  background-color: #ff9090 !important;
}

.bg-secondary-passageiros {
  background-color: #cfcfcf !important;
}

.bg-warning-passageiros {
  background-color: #ffd890 !important;
}

.MuiButton-containedPrimary {
  background-color: #0d6efd !important;
}

.MuiTimelineItem-root:before {
  flex: 0 !important;
}

.grid-timeline {
  max-height: 20vw;
  overflow-y: scroll;
}

.card-select-item-redirect .MuiPaper-root .MuiCardContent-root:last-child{
  padding-bottom: 16px !important;
}

.card-active .MuiPaper-root{
  background: #00ff80 !important;
}

.card-select-item-redirect .MuiPaper-root{
  background: #ffb500;
  cursor: pointer;
}

.card-select-item-redirect .MuiPaper-root:hover{
  background: #ffb50090;
}

.react-select__menu {
  z-index: 999999 !important;
}

.MuiPaper-rounded {
  max-width: 100% !important;
}

@media (min-width: 820px) {
  .form-modal-copiar-ocorrencia {
    width: 42vw;
  }
}