.StyledDrawer {
    & .MuiPaper-root {
      width: 570px;
      display: flex;
      flex-direction: column;
    }
  }
  
  .CabecalhoContainer {
      margin: 0.87em 0;
      display: flex;
  }
  
  .SpanText {
      font-size: 16px;
      text-transform: uppercase;
  }
  
  .GridContent {
      margin-top: 2em;
  }
  
  .GridItem {
      padding-left: 10px;
      padding-right: 10px;
  }
  
  .TextFieldCustom {
      width: 100%;
  }
  form {
    width: 100%;
  }