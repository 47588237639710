.ButtonSection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.StyledAsyncSelect {
    width: 292px;
    padding-bottom: 4px;
    margin-bottom: 10px;

    & > div {
        min-height: 54px;
    }

/* & > div[id*="listbox"] {
  height: 45px;
} */
}

.StyledAsyncSelectPontoEmbarque {
    width: 25vw;
    padding-bottom: 4px;
    margin-bottom: 10px;

    & > div {
        min-height: 54px;
    }
}