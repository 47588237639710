.StyledDrawer {
& .MuiPaper-root {
    width: 570px;
    display: flex;
    flex-direction: column;
}
}

.CabecalhoContainer {
    margin: 0.87em 0;
    display: flex;
}

.SpanText {
    font-size: 16px;
    text-transform: uppercase;
}

.GridContent {
    margin-top: 2em;
}

.GridItem {
    padding-left: 10px;
    padding-right: 10px;
}

.TextFieldCustom {
    width: 100%;
}

form {
    width: 100%;
}

.DropzonePassageiros{
    border: 2px dashed #d6a53a;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DownloadBtn {
    padding: 10px;
    border-radius: 10px;
    color: white;
    background-color: #d6a53a;
    text-decoration: none;
}

.BgError {
    background-color: #f5c2c2;
    color: #a32d2d !important;
}

.BgSuccess {
    background-color: #bdf1bd;
    color: #1a6e1e !important;
}